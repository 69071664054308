// Home.js
import React from "react";
import PageHelmet from "../components/PageHelmet";
import { HomeOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { Col, Divider, Grid, Image, Row } from "antd";
import AHService from "../components/atoms/AHService";
import LocalShipping from "@mui/icons-material/LocalShipping";
import ConstructionTwoToneIcon from "@mui/icons-material/ConstructionTwoTone";
import ReceiptLongTwoToneIcon from "@mui/icons-material/ReceiptLongTwoTone";
import MonitorHeartTwoToneIcon from "@mui/icons-material/MonitorHeartTwoTone";
import TipsAndUpdatesTwoToneIcon from "@mui/icons-material/TipsAndUpdatesTwoTone";
import DirectionsCar from "@mui/icons-material/DirectionsCar";
import welcomeCar from "../assets/auto_pozadi.png";

const cards = [
  {
    id: 1,
    name: "Osobní automobily",
    description:
      "V naší nabídce najdete osobní automobily různých světových značek.",
    img: <DirectionsCar style={{ color: "#000c78" }} />,
    route: "/nabidka",
  },
  {
    id: 2,
    name: "Užitkové automobily",
    description:
      "Zákazníkům u užitkových automobilů nabízíme možnost odečíst DPH. Problémem není ani leasing nebo spotřebitelský úvěr na financování.",
    img: <LocalShipping style={{ color: "#654200" }} />,
    route: "/nabidka",
  },
  {
    id: 3,
    name: "Servis a Pneuservis",
    description:
      "Nabízíme komplexní servis a pneuservis pro všechny typy vozidel. Na tyto služby můžete uplatnit či zakoupit i dárkový poukaz.",
    img: <ConstructionTwoToneIcon style={{ color: "#3c3c3c" }} />,
    route: "/kontakt",
  },
  {
    id: 4,
    name: "Pojistné služby a poradenství",
    description:
      "Poskytujeme poradenství a nabízíme uzavření povinného ručení a havarijního pojištění přizpůsobené individuálním potřebám zákazníka.",
    img: <ReceiptLongTwoToneIcon style={{ color: "#01717d" }} />,
    route: "/kontakt",
  },
  {
    id: 5,
    name: "Diagnostika vozu",
    description:
      "Provádíme servis a diagnostiku vozů všech značek s minimální čekací dobou.",
    img: <MonitorHeartTwoToneIcon style={{ color: "#017d16" }} />,
    route: "/kontakt",
  },
  {
    id: 6,
    name: "Montáž tažného zařízení",
    description:
      "Montáž tažného zařízení, střešních nosičů, příčníků za super ceny bez nutnosti prohlídky STK.",
    img: <TipsAndUpdatesTwoToneIcon style={{ color: "#37017d" }} />,
    route: "/kontakt",
  },
];

function Home() {
  const { useBreakpoint } = Grid;

  const screen = useBreakpoint();
  return (
    <>
      <PageHelmet title="Domů" favicon={<HomeOutlined />} />
      <Content className="page" style={{ textAlign: "center" }}>
        <div className="about-us">
          <Row style={{ rowGap: 15, paddingTop: 15, display: "flex", justifyContent: "center" }}>
            <Col md={12} style={{ display: "flex", alignItems: "center" }}>
              <div>
                <h1
                  style={{
                    marginTop: 5,
                    marginBottom: 25,
                    textAlign: screen.md ? "right" : "center",
                    paddingRight: 15,
                  }}
                >
                  AUTOHOLUBKA CZ, s.r.o.
                </h1>
                <div
                className="about-us-text"
                style={{ textAlign: screen.md ? "end" : "center" }}
                >

                <p
                  className="about-us-paragraph"
                  style={{
                    fontSize: screen.md ? 20 : 15,
                    lineHeight:
                      screen.xxl || screen.xl
                        ? 2
                        : screen.lg
                        ? 1.6
                        : screen.md
                        ? 1.4
                        : 1.3,
                  }}
                >
                  Jsme spolehlivý partner pro nákup kvalitních ojetých
                  automobilů.{" "}
                </p>
                <p
                  className="about-us-paragraph"
                  style={{
                    fontSize: screen.md ? 20 : 15,
                    lineHeight:
                      screen.xxl || screen.xl
                        ? 2
                        : screen.lg
                        ? 1.6
                        : screen.md
                        ? 1.4
                        : 1.3,
                  }}
                >
                  Nacházíme se v malebném srdci Kraje Vysočina - Novém Městě na
                  Moravě.{" "}
                </p>
                <p
                  className="about-us-paragraph"
                  style={{
                    fontSize: screen.md ? 20 : 15,
                    lineHeight:
                      screen.xxl || screen.xl
                        ? 2
                        : screen.lg
                        ? 1.6
                        : screen.md
                        ? 1.4
                        : 1.3
                  }}
                >
                  Nabízíme širokou škálu nehavarovaných dovezených ojetých
                  vozidel ze zemí EU.{" "}
                </p>
                <p
                  className="about-us-paragraph"
                  style={{
                    fontSize: screen.md ? 20 : 15,
                    lineHeight:
                      screen.xxl || screen.xl
                        ? 2
                        : screen.lg
                        ? 1.6
                        : screen.md
                        ? 1.4
                        : 1.3,
                  }}
                >
                  Každý automobil v našem autobazaru splňuje vysoké standardy
                  kvality.
                </p>
                </div>

              </div>
            </Col>
            <Col
              md={12}
              style={{
                justifyContent: "flex-start",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Image src={welcomeCar} alt="autoholubka" preview={false} />
            </Col>
          </Row>
          <Divider />
          {/* <AHService title={cards[0].name} description={cards[0].description} icon={<CarTwoTone className='service-name-icon'/>}/> */}
        </div>
        <div className="our-services">
          <h1 style={{ marginTop: 5 }}>Naše služby</h1>
          <Row
            justify={"space-evenly"}
            style={{ rowGap: 15, paddingBottom: 15 }}
          >
            {cards.map((card) => (
              <AHService
                key={card.id}
                title={card.name}
                description={card.description}
                icon={card.img}
                route={card.route}
              />
            ))}
          </Row>
        </div>
      </Content>
    </>
  );
}

export default Home;
