import { Col } from "antd"
import React from "react"
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

function AHService (props) {
    const { title, description, icon, route} = props
    const navigate = useNavigate()

    const cardHandleClick = () => {
        console.log(route)
        navigate(route)
    }

    return (
        <Col lg={7} md={10} sm={12} xs={24} style={{ padding: 20, margin:2, textAlign: 'center', cursor: 'pointer'}} onClick={cardHandleClick} className='service-col'>
            <div className='service-name-icon'>
                {icon}
            </div>
            <span className="service-name">
                {title}
            </span>
            <p>{description}</p>
        </Col>
    )
}

AHService.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    route: PropTypes.string.isRequired
};
export default AHService