import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Content } from "antd/es/layout/layout";
import {
  Image,
  Spin,
  Flex,
  Row,
  Col,
  Grid,
  Skeleton,
  Result,
  Modal,
  Form,
  Input,
  message,
} from "antd";
import { thousandsSeparator } from "../utils/formatingUtil";
import { getAllImages, getCar, sendMail } from "../components/api";
import { carDetails } from "../components/xmlUtils/dataStructure";
import { Link, useParams } from "react-router-dom";
import { CarOutlined, WhatsAppOutlined, MailOutlined } from "@ant-design/icons";
import FormItem from "antd/es/form/FormItem";
import ReCAPTCHA from "react-google-recaptcha";
import TextArea from "antd/es/input/TextArea";

const { useBreakpoint } = Grid;

const SelectedCarDetail = () => {
  const [selectedCar, setSelectedCar] = useState(null);
  const [images, setImages] = useState([]);
  const [isFetchingCarDetails, setIsFetchingCarDetails] = useState(false);
  const screens = useBreakpoint();
  const carId = useParams().carId;
  const [showSendMailModal, setShowSendMailModal] = useState(false);
  const [form] = Form.useForm();
  const [okButtonDisabled, setOkButtonDisabled] = useState(true)

  useEffect(() => {
    const fetchImages = async (id) => {
      setIsFetchingCarDetails(true);
      const res = await getCar(id);
      console.log(res);
      if (res.data.errorKey === -1) {
        setIsFetchingCarDetails(false);
        return res.data;
      }
      const carDetails = res.data;
      setSelectedCar(carDetails);
      setImages(carDetails.photos.photo);
      const photos = await getAllImages(id);
      console.log(photos);
      setImages(photos.data.photos.photo);
      console.log(images);
      // console.log(carDetail.data['offer-list'].offer)
      console.log(selectedCar);
      setIsFetchingCarDetails(false);
      return res.data;
    };
    // fetchCarData(selectedCar.id).then((res) => {})
    fetchImages(carId);
    // setImages(res.data);
  }, []);

  const getPhotoTranslation = (number) => {
    if (number === 1) {
      return "fotka";
    } else if (number > 1 && number < 5) {
      return "fotky";
    } else {
      return "fotek";
    }
  };

  const formattedPrice = () => {
    const carPrice = Number(selectedCar["sales-price"]._.replace(",", "."));
    return Math.round(
      selectedCar["vat-expel-flag"] === "ano" ? carPrice * 1.21 : carPrice
    );
  };

  const updateOkButton = (_, currentState) => {
    const containsUndefOrEmpty = Object.values(currentState).some(item => item === undefined || item === null || item === '');
    if (!containsUndefOrEmpty) {
      if (okButtonDisabled) setOkButtonDisabled(false);
    } else {
      if (!okButtonDisabled) setOkButtonDisabled(true);
    }
  };

  const sendEmail = async (formFields) => {
    console.log(formFields)
    const resp = await sendMail({...formFields, subject: `${selectedCar.producer} ${selectedCar.type} (ID: ${selectedCar.id})`, link: window.location.href})
    console.log(resp)
    if (resp.status === 200) {
      message.success('Zpráva byla odeslána')
      setShowSendMailModal(false);
      form.resetFields();
      setOkButtonDisabled(true)
    }
    else {
      message.error('Při odesílání došlo k chybě. Překontrolujte zadané hodnoty, případně zašlete zprávu přes svůj e-mail.', 10)
    }

  }

  return selectedCar ? (
    <Content
      style={{ height: "100%", overflow: !screens.lg ? "scroll" : "hidden" }}
    >
      <Modal
      title='Kontaktní formulář'
        open={showSendMailModal}
        onCancel={() => {
          setShowSendMailModal(false);
          form.resetFields();
          setOkButtonDisabled(true)
        }}
        onOk={() => form.submit()}
        okText='Odeslat'
        okButtonProps={{disabled: okButtonDisabled}}
        cancelText='Zrušit'
      >
        <Form form={form} onFinish={sendEmail} onValuesChange={updateOkButton} onFinishFailed={() => message.error('Chyba, zkontrolujte zadané hodnoty!')}>
          <FormItem required name="name" label="Jméno a příjmení">
            <Input />
          </FormItem>
          <FormItem required name="email" label="Email" rules={[{type: 'email', message: 'Neplatný formát emailu'}]}>
            <Input />
          </FormItem>
          <FormItem required name="message" label="Text zprávy">
            <TextArea rows={4} />
          </FormItem>
          <FormItem required name="recaptcha" label="Ověření">
            <ReCAPTCHA sitekey="6Lfxxf4pAAAAAHG2MSwKt2lbqOq2JLNccojm8XnS" />
          </FormItem>
        </Form>
      </Modal>
      <Row style={{ height: "100%" }}>
        <Col
          span={24}
          lg={12}
          style={{
            overflow: screens.lg ? "scroll" : "hidden",
            height: screens.lg ? "100%" : undefined,
          }}
        >
          <h1>
            {selectedCar.producer} {selectedCar.type}
            <MailOutlined
              style={{ fontSize: 25, marginLeft:15, marginRight:15, padding:8, color: "#fc804a", backgroundColor: '#ffeedc', border: '1px solid ', borderRadius:7  }}
              
              onClick={() => setShowSendMailModal(true)}
            />
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = "https://wa.me/420776176283";
                e.preventDefault();
              }}
            >
              <WhatsAppOutlined style={{ color: "#25D366", fontSize: 25, padding:8, backgroundColor: '#e9f6c6', border: '1px solid ', borderRadius:7 }} />
            </Link>
          </h1>
          <h2>
            Cena:
            <span className="car-price">
              {thousandsSeparator(formattedPrice())}
            </span>
          </h2>
          <Row>
            {carDetails.map((detail, index) => {
              return (
                selectedCar[detail.propName] && (
                  <Col
                    span={24}
                    md={12}
                    key={index}
                    style={{ paddingBottom: "10px" }}
                  >
                    <span
                      key={index}
                      style={{ fontWeight: "500", paddingRight: 5 }}
                    >
                      {detail.label}:
                    </span>
                    {Array.isArray(selectedCar[detail.propName])
                      ? selectedCar[detail.propName].join(", ")
                      : selectedCar[detail.propName]}
                  </Col>
                )
              );
            })}
          </Row>
        </Col>
        <Col span={24} lg={12} className="images-col">
          <Image.PreviewGroup style={{ height: "100%" }}>
            <div className="main-image">
              <Image
                style={{ maxWidth: "85%", objectFit: "contain" }}
                src={`data:image/jpeg;base64,${images[0].b64._}`}
              />
            </div>
            <div
              className="image-wrapper"
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              {!isFetchingCarDetails ? (
                images.slice(1).map((image, index) => {
                  if (index === 2 && images.length > 3) {
                    return (
                      <div
                        className="more-images"
                        key={index}
                        style={{
                          position: "relative",
                          display: "inline-block",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Image
                          key={index}
                          style={{ display: "flex" }}
                          className="small-car-image"
                          src={`data:image/jpeg;base64,${image.b64._}`}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: !screens.lg ? "50%" : "75%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "white",
                            fontSize: "13px",
                            fontWeight: "bold",
                            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                            pointerEvents: "none",
                            background: "rgba(0, 0, 0, 0.5)",
                            borderRadius: "15px",
                            padding: "5px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            textAlign: "center",
                          }}
                        >
                          +{images.length - 3}{" "}
                          {getPhotoTranslation(images.length - 3)}
                        </div>
                      </div>
                    );
                  } else if (index > 2) {
                    return (
                      <div key={index} style={{ display: "none" }}>
                        <Image
                          className="small-car-image"
                          src={`data:image/jpeg;base64,${image.b64._}`}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <Image
                        key={index}
                        style={{ display: index < 3 ? "flex" : "none" }}
                        className="small-car-image"
                        src={`data:image/jpeg;base64,${image.b64._}`}
                      />
                    );
                  }
                })
              ) : (
                <Row align="middle">
                  <Spin size="large" style={{ paddingRight: 15 }} />
                  <Skeleton.Image active />
                </Row>
              )}
            </div>
          </Image.PreviewGroup>
        </Col>
      </Row>
    </Content>
  ) : isFetchingCarDetails ? (
    <>
      <Flex align="center" justify="center">
        <Spin size="large" />
      </Flex>
      <Row style={{ paddingTop: "15px" }}>
        <Col span={11} style={{ marginRight: 10 }}>
          <Skeleton active />
          <Skeleton active />
        </Col>
        <Col span={11}>
          <Row justify="end">
            <Skeleton.Node active>
              <CarOutlined style={{ fontSize: 40, color: "#bfbfbf" }} />
            </Skeleton.Node>
          </Row>
        </Col>
      </Row>
    </>
  ) : (
    <Result
      status="error"
      title="Auto nenalezeno!"
      subTitle="Při získávání dat došlo k chybě. Ujistěte se, že dané auto je stále skutenčně k dispozici a zkuste to prosím znovu."
    />
  );
};

SelectedCarDetail.propTypes = {
  selectedCar: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default SelectedCarDetail;
