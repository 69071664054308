import { Col, Grid, InputNumber, Row } from "antd";
import React, { useState } from "react";
import PropTypes from "prop-types";

const FromToSelect = ({ label, from, to, setter, unit, step }) => {
  const { useBreakpoint } = Grid;

  const screen = useBreakpoint();
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFromChanged = (value) => {
      setter([value, to]);
  };
  const handleInputToChanged = (value) => {
      setter([from, value]);
  };
//   const handleInputFromBlur = (value) => {
//     if (value > to) {
//       setter([value, value]);
//     } else {
//       setter([value, to]);
//     }
//     setIsFocused(false);
//   };

//   const handleInputToBlur = (value) => {
//     if (value < from) {
//       setter([value, value]);
//     } else {
//       setter([from, value]);
//     }
//     setIsFocused(false);
//   };

  return (
    <Row align="bottom" style={{ paddingBottom: 10, alignItems: 'center' }}>
      <Col span={24} md={6}>
        <p style={{ paddingRight: 10, textAlign: screen.md ? 'end' : 'center', fontWeight: 400 }}>{label}:</p>
      </Col>
      <Col span={24} md={18} style={{display: screen.md ? 'flex' : 'grid', columnGap: 10}}>
        <InputNumber
          style={{ paddingBottom: 5 }}
          addonBefore="Od:"
          value={from}
          onChange={handleInputFromChanged}
          onFocus={() => setIsFocused(true)}
          changeOnWheel={false}
        //   onBlur={() => handleInputFromBlur(from)}
          formatter={(value) =>
            !unit
              ? `${value}`
              : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
                (!isFocused && unit ? unit : "")
          }
          parser={(value) => value.replace(/\sKč/g, "").replace(/\s/g, "")}
          step={step}
        />
        <InputNumber
          addonBefore="Do:"
          value={to}
          onChange={handleInputToChanged}
          onFocus={() => setIsFocused(true)}
          changeOnWheel={false}
        //   onBlur={() => handleInputToBlur(to)}
          formatter={(value) =>
            !unit
              ? `${value}`
              : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
                (!isFocused && unit ? unit : "")
          }
          parser={(value) => value.replace(/\sKč/g, "").replace(/\s/g, "")}
          step={step}
        />
      </Col>
    </Row>
  );
};

FromToSelect.propTypes = {
  label: PropTypes.string.isRequired,
  from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setter: PropTypes.func.isRequired,
  unit: PropTypes.string,
  step: PropTypes.number,
};

export default FromToSelect;
