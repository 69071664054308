import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Grid,
  Input,
  Modal,
  Row,
  message,
} from "antd";
import Cars from "./pages/Cars";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
// import Services from "./pages/Services";
import {
  HomeOutlined,
  CarOutlined,
  UserOutlined,
  // ToolOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import "./App.css";

import Cookies from "js-cookie";

import logo from "./assets/AUTOHOLUBKA.svg";

import HorizontalMenu from "./components/HorizontalMenu";
import { login } from "./components/api";
import { hashString } from "./utils/hashingUtil";
// import SelectedCar from "./pages/SelectedCar";

const menuItems = [
  { key: "/", path: "/", title: "Domů", icon: <HomeOutlined /> },
  {
    key: "/nabidka",
    path: "/nabidka",
    title: "Nabídka vozidel",
    icon: <CarOutlined />,
  },
  // { key: "/sluzby", path: "/sluzby", title: "Služby", icon: <ToolOutlined /> },
  {
    key: "/kontakt",
    path: "/kontakt",
    title: "Kontakt",
    icon: <UserOutlined />,
  },
];

const { useBreakpoint } = Grid;

function App() {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = React.useState(false);
  const screen = useBreakpoint();
  const [form] = Form.useForm();

  const getMenuItems = () => {
    if (isAdmin) {
      const adminMenuItems = [
        ...menuItems,
        {
          key: "logout",
          path: "/logout",
          title: "Odhlásit se",
          icon: <LogoutOutlined />,
        },
      ];
      return adminMenuItems;
    }
    return menuItems;
  };

  useEffect(() => {
    const jwt = Cookies.get("jwt");
    setIsAdmin(jwt ? true : false);
  }, []);

  const handleLoginModalClicked = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };

  const handleLoginSubmitted = async (values) => {
    console.log(form.getFieldsValue());
    //2KrasnaAuta1
    const { username, password } = values;
    //need to hash password before sending because in db is hashed version of it
    const hashPwd = hashString(password);
    console.log(username, hashPwd);
    const resp = await login({ username, password: hashPwd });
    console.log(resp);
    if (resp.data.response.jwt) {
      const jwt = resp.data.response.jwt;
      Cookies.set("jwt", jwt, { expires: 1 });
      setIsAdmin(true);
      setIsLoginModalOpen(false);
    } else {
      message.error("Nesprávné přihlašovací údaje!");
    }
  };

  return (
    <div className="content">
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              iconSize: 20,
              fontSize: 20,
              iconMarginInlineEnd: 10,
              menuItems: 50,
              // horizontalItemSelectedColor: 'red',
            },
          },
        }}
      >
        <Router>
          <div className="app-header">
            <Row style={{ height: screen.lg ? "8vh" : "12vh" }}>
              <Col xl={10} lg={12} span={24}>
                <Row
                  align="middle"
                  justify={screen.lg ? "start" : "center"}
                  style={{
                    height: "100%",
                    paddingLeft: 10,
                    paddingTop: 10,
                    paddingBottom: 5,
                  }}
                >
                  <Link to="/">
                    <img
                      src={logo}
                      alt="logo"
                      style={{ maxHeight: screen.lg ? "3em" : "2em" }}
                    />
                  </Link>
                </Row>
              </Col>
              <Col xl={14} lg={12} span={24} style={{ alignContent: "end" }}>
                <HorizontalMenu
                  justify={screen.lg ? "flex-end" : "center"}
                  menuItems={getMenuItems()}
                />
              </Col>
            </Row>
          </div>
          <div className="app-content" style={{height: screen.lg ? "92vh" : "88vh", overflow: "scroll"}}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/nabidka/:carId?"
                element={<Cars isAdmin={isAdmin} />}
              />
              {/* <Route path="/nabidka/:id" element={<SelectedCar />} /> */}
              {/* <Route path="/sluzby" element={<Services />} /> */}
              <Route path="/kontakt" exact element={<Contact />} />
            </Routes>
          </div>
            <div className="app-footer">
              <Button
                type="text"
                style={{ color: "white", cursor: "default" }}
                onDoubleClick={handleLoginModalClicked}
              >
                2024 AUTO HOLUBKA CZ, s.r.o.
              </Button>
            </div>
          <Modal
            open={isLoginModalOpen}
            onCancel={handleLoginModalClicked}
            title="Přihlášení"
            footer={false}
          >
            <Form form={form} onFinish={handleLoginSubmitted}>
              <Form.Item
                label="Uživatelské jméno"
                name="username"
                rules={[
                  { required: true, message: "Zadejte uživatelské jméno!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Heslo"
                name="password"
                rules={[{ required: true, message: "Zadejte heslo!" }]}
              >
                <Input.Password />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Přihlásit
              </Button>
            </Form>
          </Modal>
        </Router>
      </ConfigProvider>
    </div>
  );
}

export default App;
